import { createTheme, lighten, ThemeProvider } from '@mui/material'
import { GlobalStyles } from '@mui/styled-engine'
import React from 'react'

const theme = createTheme({
  palette: {
    tonalOffset: 0.5,
    primary: {
      main: '#900',
    },
    secondary: {
      main: '#FFCC00',
    },
    error: {
      main: '#900',
    },
  },
})

interface Props {}

const ThemeWrapper: React.FC<Props> = ({ children }) => (
  <>
    <GlobalStyles
      styles={{
        body: { backgroundColor: lighten(theme.palette.secondary.main, 0.9) },
      }}
    />
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </>
)

export default ThemeWrapper

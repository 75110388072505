import { Slide, SlideProps, Snackbar, SnackbarProps } from '@mui/material'
import React, { createContext, useCallback, useState } from 'react'

const SnackbarContext = createContext({
  setSnackbar(snackbar: SnackbarProps | string) {},
})

const SlideTransition: React.FC<SlideProps> = props => (
  <Slide {...props} direction="up" />
)

const DEFAULT_SNACKBAR_PROPS: Partial<SnackbarProps> = {
  autoHideDuration: 6000,
  TransitionComponent: SlideTransition,
}

interface Props {}

export const SnackbarProvider: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [snackbar, setSnackbar] = useState<SnackbarProps | null>(null)

  const setSnackbarOrSnackbarMessage = useCallback(
    (snackbarOrSnackbarMessage: SnackbarProps | string | null) => {
      if (!snackbarOrSnackbarMessage) setSnackbar(null)

      setSnackbar(
        typeof snackbarOrSnackbarMessage === 'string'
          ? { ...DEFAULT_SNACKBAR_PROPS, message: snackbarOrSnackbarMessage }
          : { ...DEFAULT_SNACKBAR_PROPS, ...snackbarOrSnackbarMessage },
      )
      setIsOpen(true)
    },
    [],
  )

  return (
    <SnackbarContext.Provider
      value={{ setSnackbar: setSnackbarOrSnackbarMessage }}
    >
      {children}
      <Snackbar {...snackbar} open={isOpen} onClose={() => setIsOpen(false)} />
    </SnackbarContext.Provider>
  )
}

export default SnackbarContext

import { IntlProvider } from 'react-intl'
import React, { createContext, useState } from 'react'

import de from 'src/lang/de.json'
import zhCn from 'src/lang/zh-CN.json'

import { DEFAULT_LOCALE } from '../constants'

const normalizeLocale = (locale: string) => {
  if (locale === 'zh' || locale.startsWith('zh-')) {
    return 'zh-CN'
  } else if (locale === 'de' || locale.startsWith('de-')) {
    return 'de'
  }

  return DEFAULT_LOCALE
}

const AVAILABLE_LOCALES = {
  de,
  'zh-CN': zhCn,
}

const IntlContext = createContext({
  locale: DEFAULT_LOCALE,
  setLocale: (locale: keyof typeof AVAILABLE_LOCALES | 'en') => {},
})

export default IntlContext

interface Props {}

export const IntlProviderWrapper: React.FC<Props> = ({ children }) => {
  const [locale, setLocale] = useState<
    typeof DEFAULT_LOCALE | keyof typeof AVAILABLE_LOCALES
  >(normalizeLocale(navigator.language))

  const normalizedLocale = normalizeLocale(locale)

  return (
    <IntlContext.Provider value={{ locale, setLocale }}>
      <IntlProvider
        // If undefined, will default to English
        messages={
          normalizedLocale === DEFAULT_LOCALE
            ? undefined
            : AVAILABLE_LOCALES[normalizedLocale]
        }
        locale={normalizedLocale}
        textComponent={React.Fragment}
        defaultLocale={DEFAULT_LOCALE}
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  )
}

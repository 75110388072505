import { FirestoreDataConverter } from 'firebase/firestore'

import Persisted from 'src/types/Persisted'

const getDefaultPersistedFirestoreDataConverter = <T>(): FirestoreDataConverter<
  Persisted<T>
> => ({
  toFirestore: (doc: Persisted<T>) => doc,
  fromFirestore: snapshot => ({
    ...(snapshot.data() as T),
    id: snapshot.id,
  }),
})

export default getDefaultPersistedFirestoreDataConverter

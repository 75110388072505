import { defineMessages } from 'react-intl'
import Role from './types/Role'

export const DEFAULT_LOCALE = 'en'
export const ROLES: Role[] = [
  'director',
  'producer',
  'cinematographer',
  'cast',
  'crew',
  'extra',
]

export const SHARED_MESSAGES = defineMessages({
  appName: {
    id: 'SharedMessages.appName',
    defaultMessage: 'SCA Tools',
  },
  formsPageTitle: {
    id: 'SharedMessages.formsPageTitle',
    description: 'The title of the Forms page',
    defaultMessage: 'Forms',
  },
  locationsPageTitle: {
    id: 'SharedMessages.locationsPageTitle',
    description: 'The title of the Locations page.',
    defaultMessage: 'Locations',
  },
  productionsPageTitle: {
    id: 'SharedMessages.productionsPageTitle',
    description: 'The title of the Productions page',
    defaultMessage: 'Productions',
  },
})

export const LOCATION_PHOTO_TYPE_MESSAGES = defineMessages({
  scout: {
    id: 'SharedMessages.LocationPhotoType.scout',
    defaultMessage: 'Location scout',
  },
  still: {
    id: 'SharedMessages.LocationPhotoType.still',
    defaultMessage: 'Film still',
  },
  bts: {
    id: 'SharedMessages.LocationPhotoType.bts',
    defaultMessage: 'Behind-the-scenes photo',
  },
  other: {
    id: 'SharedMessages.LocationPhotoType.other',
    defaultMessage: 'Other',
  },
})

import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/react'

import detectEnvironment from 'src/helpers/detectEnvironment'

Sentry.init({
  dsn: 'https://5fe3e5b391b042da8ac69f622e799fee@o1174385.ingest.sentry.io/6270347',
  integrations: [new BrowserTracing()],
  environment: detectEnvironment(),

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

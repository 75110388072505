import {
  AppBar,
  Box,
  Link as MUILink,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { defineMessages, useIntl } from 'react-intl'
import { InfoOutlined } from '@mui/icons-material'
import { Link, useMatch } from 'react-router-dom'
import React from 'react'

import AccountMenu from './AccountMenu'
import LocaleSelector from './LocaleSelector'
import { SHARED_MESSAGES } from 'src/constants'

const M = defineMessages({
  appCredit: {
    defaultMessage: 'by Jesse Pinho',
    id: 'NavBar.appCredit',
  },
  appInfoMessage: {
    defaultMessage:
      'Not affiliated with USC or SCA in any official capacity. Questions: jpinho@usc.edu',
    id: 'NavBar.appInfoMessage',
  },
  homeLabel: {
    defaultMessage: 'How it works',
    id: 'NavBar.homeLabel',
  },
  crewLabel: {
    defaultMessage: 'Crew',
    id: 'NavBar.crewLabel',
  },
})

interface Props {}

const NavBar: React.FC<Props> = () => {
  const intl = useIntl()

  const matches = [
    useMatch('/'),
    useMatch('/productions/*'),
    useMatch('/forms/*'),
    useMatch('/locations/*'),
    useMatch('/crew/*'),
  ]
  let currentTabIndex: number | false = matches.findIndex(match => !!match)
  currentTabIndex = currentTabIndex === -1 ? false : currentTabIndex

  return (
    <>
      {/* TODO: replace this with the proper way to shim from here: https://github.com/mui/material-ui/issues/10076#issuecomment-361232810. (This is a shim to push the layout down below the fixed-height header.) */}
      <AppBar position="static" sx={{ boxShadow: 0 }}>
        <Toolbar />

        <Tabs value={0} variant="scrollable" />
      </AppBar>

      <AppBar position="fixed" /* sx={{ minHeight: 128 }}*/>
        <Toolbar>
          <Typography
            variant="h6"
            component={Link}
            to="/"
            sx={{
              flexShrink: 0,
              mr: 1,
              textDecoration: 'none',
              color: 'white',
            }}
          >
            {intl.formatMessage(SHARED_MESSAGES.appName)}
          </Typography>
          <Typography
            color="primary.light"
            variant="body2"
            sx={{
              flexShrink: 0,
              pt: 0.5,
            }}
          >
            <MUILink
              href="https://jessepinho.com"
              target="_blank"
              underline="hover"
              color="inherit"
            >
              {intl.formatMessage(M.appCredit)}
            </MUILink>
          </Typography>

          <Box
            color="primary.light"
            sx={{ ml: 1, position: 'relative', flexGrow: 1 }}
          >
            <Tooltip
              title={intl.formatMessage(M.appInfoMessage)}
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              arrow
            >
              <Box
                sx={{
                  position: 'absolute',

                  // Create a large tap target for the tooltip
                  transform: {
                    xs: 'translate(-10px, -10px)',
                    sm: 'translate(-14px, -14px)',
                  },
                  width: 44,
                  height: 44,
                }}
              />
            </Tooltip>

            <InfoOutlined
              sx={{
                fontSize: { xs: 24, sm: 16 },
                verticalAlign: { xs: 'middle', sm: 'text-bottom' },
              }}
            />
          </Box>

          <Box
            sx={{
              width: { xs: 24, sm: 35 },
              mr: { xs: 0.5, sm: 1 },
              flexShrink: 0,
              fontSize: { xs: 24, sm: 36 },
            }}
          >
            <LocaleSelector />
          </Box>

          <AccountMenu />
        </Toolbar>
        <Tabs
          value={currentTabIndex}
          textColor="inherit"
          indicatorColor="secondary"
          variant="scrollable"
        >
          <Tab
            component={Link}
            to="/"
            label={intl.formatMessage(M.homeLabel)}
          />
          <Tab
            component={Link}
            to="/productions"
            label={intl.formatMessage(SHARED_MESSAGES.productionsPageTitle)}
          />
          <Tab
            component={Link}
            to="/forms"
            label={intl.formatMessage(SHARED_MESSAGES.formsPageTitle)}
          />
          <Tab
            component={Link}
            to="/locations"
            label={intl.formatMessage(SHARED_MESSAGES.locationsPageTitle)}
          />
          <Tab
            component={Link}
            to="/crew"
            label={intl.formatMessage(M.crewLabel)}
          />
        </Tabs>
      </AppBar>
    </>
  )
}

export default NavBar

import { useContext } from 'react'

import SnackbarContext from 'src/components/SnackbarContext'

/**
 * Just a lil hook to avoid having to import both `useContext` and
 * `SnackbarContext`.
 */
const useSnackbar = () => useContext(SnackbarContext)

export default useSnackbar

import { defineMessages, useIntl } from 'react-intl'
import { AccountCircle, Logout, Person } from '@mui/icons-material'
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import React, { useContext, useState } from 'react'

import AuthContext from '../AuthContext'
import useFirebase from 'src/hooks/useFirebase'

const M = defineMessages({
  button: {
    id: 'NavBar.AccountMenu.button',
    defaultMessage: 'Your account',
  },
  profileLabel: {
    id: 'NavBar.AccountMenu.profileLabel',
    defaultMessage: 'Profile',
  },
  logOutLabel: {
    defaultMessage: 'Log out',
    id: 'NavBar.AccountMenu.logOutLabel',
  },
})

interface Props {}

const AccountMenu: React.FC<Props> = () => {
  const intl = useIntl()
  const { auth } = useFirebase()
  const { user } = useContext(AuthContext)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const navigate = useNavigate()

  const handleClickLogOut = () => {
    navigate('/')
    auth.signOut()
  }

  if (!user?.uid) return null

  return (
    <>
      <IconButton
        size="small"
        sx={{ color: 'white' }}
        aria-label={intl.formatMessage(M.button)}
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <AccountCircle />
      </IconButton>

      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          component={Link}
          to="/profile"
          onClick={() => setAnchorEl(null)}
        >
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          <ListItemText>{intl.formatMessage(M.profileLabel)}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickLogOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText>{intl.formatMessage(M.logOutLabel)}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default AccountMenu

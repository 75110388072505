import { defineMessages, useIntl } from 'react-intl'
import { doc, getDoc } from 'firebase/firestore'
import { onAuthStateChanged, User } from 'firebase/auth'
import React, { createContext, useEffect, useState } from 'react'

import getDefaultPersistedFirestoreDataConverter from 'src/firestoreDataConverters/default'
import Persisted from 'src/types/Persisted'
import Profile from 'src/types/Profile'
import useFirebase from 'src/hooks/useFirebase'
import validateEmail from 'src/helpers/validateEmail'
import { useNavigate } from 'react-router-dom'
import useSnackbar from 'src/hooks/useSnackbar'

const M = defineMessages({
  setUpProfileMessage: {
    id: 'AuthContext.setUpProfileMessage',
    defaultMessage:
      'Set up your profile to make it easier for USC students to add you to productions.',
  },
})

const AuthContext = createContext<{
  isCheckingAuth: boolean
  profile?: Profile
  user: User | null
  setUser: (user: User) => void
}>({
  isCheckingAuth: false,
  user: null,
  setUser: () => {},
})

const converter = getDefaultPersistedFirestoreDataConverter<Profile>()

interface Props {}

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const intl = useIntl()
  const { auth, db } = useFirebase()
  const userFromAuth = auth.currentUser
  const [user, setUser] = useState<User | null>(userFromAuth)
  const [profile, setProfile] = useState<Persisted<Profile> | undefined>()
  const [isCheckingAuth, setIsCheckingAuth] = useState<boolean>(true)
  const navigate = useNavigate()
  const { setSnackbar } = useSnackbar()

  useEffect(
    () =>
      onAuthStateChanged(auth, async currentUser => {
        if (currentUser && validateEmail(currentUser.email)) {
          setUser(currentUser)
          const snapshot = await getDoc(
            doc(db, 'profiles', currentUser.uid).withConverter(converter),
          )
          setProfile(snapshot.data())
          if (!snapshot.data()) {
            navigate('/profile')
            setSnackbar(intl.formatMessage(M.setUpProfileMessage))
          }
        } else {
          setUser(null)
          auth.signOut()
        }
        setIsCheckingAuth(false)
      }),

    // If we include `navigate` as a `useEffect` dependency here, it'll
    // re-trigger this effect on every route change, since the value of
    // `navigate` changes on every route change. This is a known issue
    // (https://github.com/remix-run/react-router/issues/7634); until it's
    // fixed, we'll just disable the ESLint complaint about exhaustive
    // dependencies.
    //
    // When changing the contents of the `useEffect` hook above, remove the
    // `eslint-disable...` line temporarily to check if anything _else_ is
    // missing.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth, db, intl, setSnackbar],
  )

  return (
    <AuthContext.Provider value={{ user, profile, setUser, isCheckingAuth }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext

import { useContext } from 'react'

import FirebaseContext from 'src/components/FirebaseContext'

/**
 * Just a lil hook to avoid having to import both `useContext` and
 * `FirebaseContext`.
 */
const useFirebase = () => useContext(FirebaseContext)

export default useFirebase

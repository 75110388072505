import { CircularProgress, Container } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import React, { useContext } from 'react'

import AuthContext, { AuthContextProvider } from './components/AuthContext'
import CenteredSpinner from './components/CenteredSpinner'
import NavBar from './components/NavBar'
import { SnackbarProvider } from './components/SnackbarContext'
import ThemeWrapper from './components/ThemeWrapper'

const CrewPage = React.lazy(() => import('./components/CrewPage'))
const FormsPage = React.lazy(() => import('./components/FormsPage'))
const HomePage = React.lazy(() => import('./components/HomePage'))
const LocationsPage = React.lazy(() => import('./components/LocationsPage'))
const ProductionsPage = React.lazy(() => import('./components/ProductionsPage'))
const ProfilePage = React.lazy(() => import('./components/ProfilePage'))

function App() {
  const { isCheckingAuth } = useContext(AuthContext)

  return (
    <ThemeWrapper>
      <SnackbarProvider>
        <AuthContextProvider>
          <NavBar />

          <Container maxWidth="md" sx={{ my: 2 }}>
            {isCheckingAuth ? (
              <CircularProgress
                sx={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            ) : (
              <React.Suspense fallback={<CenteredSpinner />}>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/productions/*" element={<ProductionsPage />} />
                  <Route path="/forms/*" element={<FormsPage />} />
                  <Route path="/crew/*" element={<CrewPage />} />
                  <Route path="/locations/*" element={<LocationsPage />} />
                </Routes>
              </React.Suspense>
            )}
          </Container>
        </AuthContextProvider>
      </SnackbarProvider>
    </ThemeWrapper>
  )
}

export default App

import { CircularProgress } from '@mui/material'
import React from 'react'

interface Props {}

const CenteredSpinner: React.FC<Props> = () => (
  <CircularProgress
    sx={{
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  />
)

export default CenteredSpinner

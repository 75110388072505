/**
 * Make sure the user has an @usc.edu or @cinema.usc.edu Google account.
 */
const validateEmail = (emailAddress: string | undefined | null) => {
  if (!emailAddress) return false
  const chunks = emailAddress.split('@')
  return chunks[1] === 'usc.edu' || chunks[1] === 'cinema.usc.edu'
}

export default validateEmail

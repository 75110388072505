import React, { useContext } from 'react'

import IntlContext from '../IntlProviderWrapper'
import Locale from 'src/types/Locale'

interface Props {}

const LocaleSelector: React.FC<Props> = () => {
  const { locale, setLocale } = useContext(IntlContext)

  return (
    <select
      aria-label="foooo"
      value={locale}
      onChange={(event) => setLocale(event.currentTarget.value as Locale)}
      style={{
        appearance: 'none',
        background: 'none',
        border: 'none',
        color: 'white',
        fontSize: 'inherit',
        padding: 0,
        WebkitAppearance: 'none',
      }}
    >
      <option value="de" aria-label="Deutsch">
        🇩🇪
      </option>
      <option value="en" aria-label="English">
        🇺🇸
      </option>
      <option value="zh-CN" aria-label="中国人">
        🇨🇳
      </option>
    </select>
  )
}

export default LocaleSelector

import { createContext } from 'react'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

import { firebaseApp } from '../initializers/firebase'

const auth = getAuth(firebaseApp)
const db = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)

const FirebaseContext = createContext({ app: firebaseApp, auth, db, storage })

export default FirebaseContext

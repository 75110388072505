import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'

import detectEnvironment from '../helpers/detectEnvironment'

export const firebaseApp = initializeApp({
  apiKey: 'AIzaSyDwSmNm9TNDpLkJR7yB_1c-YpKO71cgX-U',
  appId: '1:352606731645:web:99c2d61295b5988ca4e014',
  authDomain:
    detectEnvironment() === 'development' ? 'localhost' : 'sca.jessepinho.com',
  projectId: 'usc-sca-apps',
  measurementId: 'G-7365JTP2P7',
  storageBucket: 'usc-sca-apps.appspot.com',
})

if (detectEnvironment() === 'development') {
  connectAuthEmulator(getAuth(firebaseApp), 'http://localhost:9099', {
    disableWarnings: true,
  })
  connectFirestoreEmulator(getFirestore(firebaseApp), 'localhost', 8080)
  connectStorageEmulator(getStorage(firebaseApp), 'localhost', 9199)
} else if (detectEnvironment() === 'production') {
  getAnalytics(firebaseApp)
}

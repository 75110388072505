import { BrowserRouter } from 'react-router-dom'
import React from 'react'
import ReactDOM from 'react-dom'

// Import initializers before any of our own components
import './initializers/firebase'
import './initializers/sentry'

import App from './App'
import { IntlProviderWrapper } from './components/IntlProviderWrapper'
import reportWebVitals from './reportWebVitals'

import './reset.css'

ReactDOM.render(
  <React.StrictMode>
    <IntlProviderWrapper>
      <React.Suspense fallback={null}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.Suspense>
    </IntlProviderWrapper>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
